import {
  BigNumber,
  BigNumberInWei,
  BigNumberInBase
} from '@injectivelabs/utils'
import { ChainId } from '@injectivelabs/ts-types'
import { Network } from '@injectivelabs/networks'
import { NetworkId } from '../../types/enums'

export const IS_DEVELOPMENT: boolean = process.env.NODE_ENV === 'development'
export const IS_PRODUCTION: boolean = process.env.NODE_ENV === 'production'

export const MAIN_ETHEREUM_NETWORK_ID: number = 1
export const ZERO: BigNumber = new BigNumber(0)
export const ZERO_IN_WEI: BigNumberInWei = new BigNumberInWei(0)
export const ZERO_IN_BASE: BigNumberInBase = new BigNumberInBase(0)
export const UNLIMITED_ALLOWANCE_IN_BASE_UNITS: BigNumber = new BigNumber(2)
  .pow(256)
  .minus(1)

export const TX_DEFAULTS: Object = {
  gas: 80_000_000,
  gasPrice: 0
}

export const WINDOW_HEIGHT_BREAKING_POINT = 800
export const PROTOCOL_FEE_MULTIPLIER: number = 150000
export const MAKER_FEE_MULTIPLIER: BigNumber = new BigNumber(0.0015)
export const TAKER_FEE_MULTIPLIER: BigNumber = new BigNumber(0.001)

export const ZERO_ADDRESS: string = '0x0000000000000000000000000000000000000000'
export const ZERO_BYTES_32: string =
  '0x0000000000000000000000000000000000000000000000000000000000000000'
export const ZERO_MARKET_ID: string =
  '0x000000000000000000000000000000000000000000000000000000000000000000000000'
export const NULL_BYTES: string = '0x'

export const FEE_RECIPIENT: string =
  process.env.APP_FEE_RECIPIENT || ZERO_ADDRESS
export const FEE_PERCENTAGE: BigNumber = new BigNumber(
  process.env.APP_FEE_PERCENTAGE || ZERO
)

export const SECONDS_IN_A_DAY: BigNumber = new BigNumber(60 * 60 * 24)
export const DEFAULT_ORDER_EXPIRY_SECONDS: BigNumber = process.env
  .APP_DEFAULT_ORDER_EXPIRY_SECONDS
  ? new BigNumber(process.env.APP_DEFAULT_ORDER_EXPIRY_SECONDS)
  : SECONDS_IN_A_DAY.times(7)

export const GWEI_IN_WEI: BigNumber = new BigNumber(1000000000)
export const ONE_MINUTE_MS: number = 1000 * 60
export const DEFAULT_GAS_PRICE: BigNumberInWei = new BigNumberInWei(1000000000)
export const DEFAULT_ESTIMATED_TRANSACTION_TIME_MS: number = ONE_MINUTE_MS * 2

export const DEFAULT_TESTNET_GAS_PRICE: BigNumberInWei = new BigNumberInWei(0)
export const DEFAULT_TESTNET_ESTIMATED_TRANSACTION_TIME_MS: number = DEFAULT_ESTIMATED_TRANSACTION_TIME_MS

export const DEFAULT_DECIMAL_PLACES: number = 18
export const UI_DEFAULT_DECIMAL_PLACES: number = 4

export const RELAYER_RPS: number = 10

export const BASE_URL: string = process.env.APP_BASE_URL
export const AIRDROP_API: string = process.env.APP_AIRDROP_API
export const MINTSCAN_BASE_URL: string =
  'https://testnet-explorer-api.injective.dev/v1'
export const INJ_ID: string = 'injective-protocol'
export const MAILCHIMP_API: string =
  'https://76af9h1lq2.execute-api.us-east-2.amazonaws.com/v1/MailchimpHandler'

export const NETWORK: Network = process.env.APP_NETWORK || Network.Mainnet
export const NETWORK_ID: NetworkId =
  process.env.APP_NETWORK_ID || NetworkId.Testnet
export const CHAIN_ID: ChainId = process.env.APP_CHAIN_ID || ChainId.Injective
export const RPC_POLING_INTERVAL: number = 60 * 60 * 1000

export const STAKING_RATIO = new BigNumber(10).pow(18)
export const MAINNET_CHAIN_ID = 1
export const TESTNET_CHAIN_ID = 42
export const INJECTIVE_CHAIN_ID = 888
export const INJ_FEE_BUFFER = 0.005
export const TESTNET_GAS_PRICE = new BigNumber(6).times(GWEI_IN_WEI)
