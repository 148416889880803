/* eslint-disable camelcase */
import { Coin } from '@injectivelabs/chain-api/cosmos/base/v1beta1/coin_pb'
import {
  Validator as GrpcValidator,
  Delegation as GrpcDelegation,
  UnbondingDelegation as GrpcUnBondingDelegation,
  DelegationResponse as GrpcDelegationResponse
} from '@injectivelabs/chain-api/cosmos/staking/v1beta1/staking_pb'
import {
  OracleTypeMap as GrpcOracleTypeMap,
  OracleTypeMap
} from '@injectivelabs/chain-api/injective/oracle/v1beta1/oracle_pb'
import { GrpcTallyResult } from '@injectivelabs/chain-consumer'
import {
  BigNumber,
  BigNumberInBase,
  BigNumberInWei
} from '@injectivelabs/utils'
import { GrpcInsuranceFund, GrpcOracle } from '@injectivelabs/exchange-consumer'
import { Token } from './token'

export enum BondStatus {
  UnBonded = 'UnBonded',
  UnBonding = 'UnBonding',
  Bonded = 'Bonded'
}

export interface UiReward {
  validatorAddress: string
  rewards: {
    denom: string
    amount: string
  }[]
}

export interface UiDelegator {
  delegation: {
    delegatorAddress: string
    validatorAddress: string
    shares: BigNumberInWei
  }
  balance: {
    denom: string
    amount: BigNumberInBase
  }
}

export interface UiReDelegator {
  delegation: {
    delegatorAddress: string
    sourceValidatorAddress: string
    completionTime: number
    destinationValidatorAddress: string
  }
  balance: BigNumberInBase
}

export interface UiUnBondingDelegator {
  delegatorAddress: string
  validatorAddress: string
  creationHeight: number
  completionTime: number
  initialBalance: BigNumberInWei
  balance: BigNumberInWei
}

export interface ValidatorDescription {
  moniker: string
  identity: string
  website: string
  securityContact: string
  details: string
}

export interface ValidatorCommission {
  commissionRates: {
    rate: BigNumberInBase
    maxRate: BigNumberInBase
    maxChangeRate: BigNumberInBase
  }
  updateTime: Date
}

export interface Validator {
  operatorAddress: string
  consensusPubKey: string
  jailed: boolean
  status: BondStatus
  tokens: BigNumberInWei
  delegatorShares: BigNumberInWei
  description: ValidatorDescription
  unBondingHeight: number
  unBondingTime: any
  commission: ValidatorCommission
  minSelfDelegation: BigNumberInWei
}

export interface UiValidator {
  jailed: boolean
  status: BondStatus
  unBondingTime: number
  unBondingHeight: number
  stakedAmount: BigNumberInWei
  delegatedAmount: BigNumberInWei
  selfDelegation: BigNumberInWei
  commissionRate: BigNumber
  name: string
  address: string
  delegators: UiDelegator[]
}

export interface NetworkStatus {
  chain_id: string
  block_time: number
  latest_block_height: number
  total_validator_num: number
  timestamp: Date
}

export interface UiNetworkStatus {
  chainId: string
  blockTime: number
  latestBlockHeight: number
  totalValidatorNum: number
  timestamp: Date
}

export interface BasicData {
  currency: string
  current_price: string
  market_cap: string
  total_volume: string
  percent_change_24h: string
  last_updated_at: Date
}

export interface UiBasicData {
  currency: string
  price: string
  marketCap: string
  vol24h: string
  change24h: string
  lastUpdatedAt: Date
}

export interface CosmosDetails {
  cosmosAddress: string
  sequence?: number
  accountNumber?: number
}

export enum ProposalStatusNumber {
  PROPOSAL_STATUS_UNSPECIFIED = 0,
  PROPOSAL_STATUS_DEPOSIT_PERIOD = 1,
  PROPOSAL_STATUS_VOTING_PERIOD = 2,
  PROPOSAL_STATUS_PASSED = 3,
  PROPOSAL_STATUS_REJECTED = 4,
  PROPOSAL_STATUS_FAILED = 5
}

export enum ProposalStatus {
  Unspecified = 'PROPOSAL_STATUS_UNSPECIFIED',
  DepositPeriod = 'PROPOSAL_STATUS_DEPOSIT_PERIOD',
  VotingPeriod = 'PROPOSAL_STATUS_VOTING_PERIOD',
  Passed = 'PROPOSAL_STATUS_PASSED',
  Rejected = 'PROPOSAL_STATUS_REJECTED',
  Failed = 'PROPOSAL_STATUS_FAILED',
  PROPOSAL_STATUS_UNSPECIFIED = 0,
  PROPOSAL_STATUS_DEPOSIT_PERIOD = 1,
  PROPOSAL_STATUS_VOTING_PERIOD = 2,
  PROPOSAL_STATUS_PASSED = 3,
  PROPOSAL_STATUS_REJECTED = 4,
  PROPOSAL_STATUS_FAILED = 5
}

export type Deposit = {
  denom: string
  amount: string
}

export interface UiGovParams {
  votingParams: {
    votingPeriod: string
  }
  depositParams: {
    minInjDeposit: Deposit
    minDeposits: Deposit[]
    maxDepositPeriod: string
  }
  tallyParams: {
    quorum: string | Uint8Array
    threshold: string | Uint8Array
    vetoThreshold: string | Uint8Array
  }
}

export interface UiInsuranceParams {
  defaultRedemptionNoticePeriodDuration: number
}

export enum PaginationOption {
  next = 'next',
  prev = 'prev'
}

export type Pagination = string | undefined | null

export interface StakingPagination {
  proposals: {
    next: Pagination
    current: Pagination
    prev: Pagination
  }
  redelegations: {
    next: Pagination
    current: Pagination
    prev: Pagination
  }
  unbonding: {
    next: Pagination
    current: Pagination
    prev: Pagination
  }
  delegations: {
    next: Pagination
    current: Pagination
    prev: Pagination
  }
}

export type ProposalDeposit = Coin

export type UiCoin = {
  amount: string
  denom: string
}

export type UiSupplyCoin = {
  amount: string
  denom: string
  mappedDenom: {
    label: string
    code: string
  }
}

export interface UiProposal {
  proposalId: number
  content: any
  status: ProposalStatus
  submitTime: number
  finalTallyResult: GrpcTallyResult.AsObject | undefined
  totalDeposits: UiCoin[]
  votingStartTime: number
  votingEndTime: number
  depositEndTime: number
}

export interface VoteOptionMap {
  VOTE_OPTION_UNSPECIFIED: 0
  VOTE_OPTION_YES: 1
  VOTE_OPTION_ABSTAIN: 2
  VOTE_OPTION_NO: 3
  VOTE_OPTION_NO_WITH_VETO: 4
}

export enum VoteOptionNumber {
  VOTE_OPTION_UNSPECIFIED = 0,
  VOTE_OPTION_YES = 1,
  VOTE_OPTION_ABSTAIN = 2,
  VOTE_OPTION_NO = 3,
  VOTE_OPTION_NO_WITH_VETO = 4
}

export enum VoteOption {
  Unspecified = 'VOTE_OPTION_UNSPECIFIED',
  Yes = 'VOTE_OPTION_YES',
  Abstain = 'VOTE_OPTION_ABSTAIN',
  No = 'VOTE_OPTION_NO',
  NoWithVeto = 'VOTE_OPTION_NO_WITH_VETO',
  VOTE_OPTION_UNSPECIFIED = 0,
  VOTE_OPTION_YES = 1,
  VOTE_OPTION_ABSTAIN = 2,
  VOTE_OPTION_NO = 3,
  VOTE_OPTION_NO_WITH_VETO = 4
}

export type UiVote = {
  proposalId: number
  voter: string
  option: VoteOption
}

export type UiTallyResult = {
  yes: string
  abstain: string
  no: string
  noWithVeto: string
}

export type UiProposalDeposit = {
  depositor: string
  amounts: UiCoin[]
}

export interface UiSingleProposal extends UiProposal {
  votes: UiVote[]
  deposits: UiProposalDeposit[]
  tallyResult: UiTallyResult
}

export interface SpotMarketLaunchProposal {
  baseDenom: string
  quoteDenom: string
  title: string
  description: string
  ticker: string
  minPriceTickSize: string
  minQuantityTickSize: string
}

export interface PerpetualMarketLaunchProposal {
  title: string
  description: string
  ticker: string
  quoteDenom: string
  oracleBase: string
  oracleQuote: string
  oracleScaleFactor: number
  oracleType: OracleTypeMap[keyof OracleTypeMap]
  initialMarginRatio: string
  maintenanceMarginRatio: string
  makerFeeRate: string
  takerFeeRate: string
  minPriceTickSize: string
  minQuantityTickSize: string
}

export interface ExpiryFuturesMarketLaunchProposal {
  title: string
  description: string
  ticker: string
  quoteDenom: string
  oracleBase: string
  oracleQuote: string
  oracleScaleFactor: number
  oracleType: OracleTypeMap[keyof OracleTypeMap]
  initialMarginRatio: string
  maintenanceMarginRatio: string
  makerFeeRate: string
  takerFeeRate: string
  minPriceTickSize: string
  minQuantityTickSize: string
  expiry: number
}

export interface SpotMarketUpdateProposal {
  title: string
  description: string
  marketId: string
  makerFeeRate: string
  takerFeeRate: string
  relayerFeeShareRate: string
  minPriceTickSize: string
  minQuantityTickSize: string
}

export enum UiMarketStatus {
  Active = 'active',
  Paused = 'paused',
  Suspended = 'suspended',
  Demolished = 'demolished',
  Expired = 'expired'
}

export interface UiSpotMarket {
  ticker: string
  baseDenom: string
  quoteDenom: string
  makerFeeRate: string
  takerFeeRate: string
  relayerFeeShareRate: string
  marketId: string
  status: UiMarketStatus
  minPriceTickSize: string
  minQuantityTickSize: string
}

export enum UiOracleType {
  Unspecified = 'UNSPECIFIED',
  Band = 'BAND',
  Pricefeed = 'PRICEFEED',
  Coinbase = 'COINBASE',
  Chainlink = 'CHAINLINK',
  Razor = 'RAZOR',
  Dia = 'DIA',
  Api3 = 'API3',
  Uma = 'UMA',
  Pyth = 'PYTH',
  Bandibc = 'BANDIBC',
  UNSPECIFIED = 0,
  BAND = 1,
  PRICEFEED = 2,
  COINBASE = 3,
  CHAINLINK = 4,
  RAZOR = 5,
  DIA = 6,
  API3 = 7,
  UMA = 8,
  PYTH = 9,
  BANDIBC = 10
}

export interface UiInsuranceFund {
  depositDenom: string
  insurancePoolTokenDenom: string
  redemptionNoticePeriodDuration?: number
  depositTokenMeta?: Token | undefined
  balance: string
  totalShare: string
  marketId: string
  marketTicker: string
  oracleBase: string
  oracleQuote: string
  oracleType: UiOracleType
  expiry: number
}

export enum UiRedemptionStatus {
  Pending = 'pending',
  Disbursed = 'disbursed'
}

export interface UiRedemption {
  redemptionId: number
  status: UiRedemptionStatus
  redeemer: string
  claimableRedemptionTime: number
  redemptionAmount: string
  redemptionDenom: string
  requestedAt: number
  disbursedAmount: string
  disbursedDenom: string
  disbursedAt: number
}

export interface UiOracle {
  symbol: string
  baseSymbol: string
  quoteSymbol: string
  oracleType: UiOracleType
  price: string
}

export {
  GrpcOracle,
  GrpcOracleTypeMap,
  GrpcValidator,
  GrpcDelegationResponse,
  GrpcUnBondingDelegation,
  GrpcDelegation,
  GrpcInsuranceFund
}
