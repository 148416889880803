


































































import Vue from 'vue'
import { directive as onClickaway } from 'vue-clickaway'
import { StakingStoreModule } from 'app/store/accessor'
import { AccountAddress } from '~/types'

export default Vue.extend({
  directives: {
    onClickaway
  },

  data() {
    return {
      addressCopied: false,
      isDropdownOpen: false
    }
  },

  computed: {
    addresses(): AccountAddress[] {
      return StakingStoreModule.addresses
    },

    address(): AccountAddress {
      const { addresses } = this
      const [address] = addresses

      return address || ''
    }
  },

  methods: {
    clipboardSuccessHandler() {
      if (!this.addressCopied) {
        this.$toast.success(this.$t('staking.copy_address_done'))
      }

      this.addressCopied = true
    },

    clipboardErrorHandler() {
      // handle error
    },

    revertCopiedMessage() {
      this.addressCopied = false
    },

    closeDropdown() {
      if (this.isDropdownOpen) {
        this.isDropdownOpen = false
      }
    },

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen
    },

    onLogout() {
      this.$emit('closed')
      StakingStoreModule.logout()
      this.$router.push({ name: 'connect' })
    }
  }
})
