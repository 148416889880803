export enum MetricsGraphQueries {}
//

export enum MetricsChronosRequests {
  GetValidators = 'GetValidators'
}
//

export enum MetricsGrpcQueries {
  GetValidators = 'GetValidators',
  GetBalance = 'GetBalance',
  GetCosmosAccount = 'GetCosmosAccount',
  GetValidatorDelegators = 'GetValidatorDelegators',
  GetUnBondingDelegations = 'GetUnBondingDelegations',
  PrepareCosmosTx = 'PrepareCosmosTx',
  BroadcastCosmosTx = 'BroadcastCosmosTx'
}
//

export enum MetricsGrpcRequests {}
//

export enum MetricsPages {}
//
