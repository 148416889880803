import { Middleware, Context } from '@nuxt/types'
import { StakingStoreModule } from 'app/store/accessor'

const homeRoute = 'index'

const allToHomepage: Middleware = ({ redirect, route }: Context) => {
  const routeIsHome = route.name && route.name === homeRoute
  const isUserWalletConnected = StakingStoreModule.isUserWalletConnected

  if (isUserWalletConnected && !routeIsHome) {
    return redirect('/')
  }
}

export default allToHomepage
