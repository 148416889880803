import { Middleware, Context } from '@nuxt/types'
import { StakingStoreModule } from 'app/store/accessor'

const connectPageRoute = 'connect'

const stakingMiddleware: Middleware = ({ redirect, route }: Context) => {
  const routeIsNotConnect = route.name && route.name !== connectPageRoute
  const routeIsConnect = route.name && route.name === connectPageRoute
  const isUserWalletConnected = StakingStoreModule.isUserWalletConnected

  if (isUserWalletConnected && routeIsConnect) {
    return redirect('/')
  }

  if (!isUserWalletConnected && routeIsNotConnect) {
    return redirect('/connect')
  }
}

export default stakingMiddleware
