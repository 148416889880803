












import Vue from 'vue'
import { StakingStoreModule } from 'app/store/accessor'

export default Vue.extend({
  computed: {
    isUserWalletConnected(): boolean {
      return StakingStoreModule.isUserWalletConnected
    },

    theme(): 'dark' | 'light' {
      return StakingStoreModule.theme
    }
  },

  watch: {
    theme(newValue) {
      if (newValue === 'dark') {
        document.body.classList.add('dark')
      } else {
        document.body.classList.remove('dark')
      }
    }
  },

  mounted() {
    if (this.theme === 'dark') {
      document.body.classList.add('dark')
    }
  },

  methods: {
    onThemeSwitch() {
      StakingStoreModule.toggleTheme()
    }
  }
})
