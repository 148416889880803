
import Vue, { VNode, PropType } from 'vue'
import { formatPrice } from 'app/utils/formatters'
import { BigNumberInWei } from '@injectivelabs/utils'
import { Token } from '~/types'

export default Vue.extend({
  props: {
    prefix: {
      required: false,
      type: String,
      default: ''
    },

    suffix: {
      required: false,
      type: String,
      default: ''
    },

    value: {
      required: true,
      type: Object as PropType<BigNumberInWei>
    },

    token: {
      required: true,
      type: Object as PropType<Token>
    }
  },

  computed: {
    formatFunction(): Function {
      return formatPrice
    }
  },

  render(createElement): VNode {
    const parentAttributes = {
      attrs: this.$attrs,
      on: this.$listeners
    }

    return createElement('v-ui-format-number', {
      ...parentAttributes,
      props: {
        formatter: this.formatFunction,
        token: this.token,
        suffix: this.suffix,
        prefix: this.prefix,
        value: this.value
      }
    })
  }
})
