import merge from 'deepmerge'
import { Store } from 'vuex'
import { getStorage } from 'app/singletons/Storage'
import { StakingStoreModule } from './accessor'

export const VuexRestorePlugin = (store: Store<any>) => {
  store.replaceState(merge(store.state, getStorage().get('state') as any))
}

const mutationsToPersist = [
  'staking/toggleTheme',
  'staking/setAddresses',
  'staking/setAddress',
  'staking/setCosmosDetails',
  'staking/setAddressConfirmation'
]

export const VuexPersistentPlugin = (store: Store<any>) => {
  store.subscribe(({ type }) => {
    /**
     * Persist store when only when user is not on testnet.
     */
    if (mutationsToPersist.includes(type)) {
      const storage = getStorage()
      const stateToPersist = {
        staking: {
          theme: StakingStoreModule.theme,
          wallet: StakingStoreModule.wallet,
          addresses: StakingStoreModule.addresses,
          address: StakingStoreModule.address,
          cosmosDetails: StakingStoreModule.cosmosDetails,
          addressConfirmation: StakingStoreModule.addressConfirmation
        }
      }

      storage.set('state', stateToPersist)
    }
  })
}
