import { getStorage } from 'app/singletons/Storage'
import { Locale, locales } from '~/locales'

export const getLocale = (): Locale | undefined => {
  const browserLocale = navigator ? navigator.language.split('-')[0] : null
  const localStorageLocale = getStorage().get('locale', null)

  return locales.find(({ locale }: Locale) => {
    if (localStorageLocale) {
      return locale === localStorageLocale
    }

    if (browserLocale) {
      return locale === browserLocale
    }

    return false
  })
}
