












import Vue from 'vue'

export default Vue.extend({
  props: {
    title: {
      required: true,
      default: '',
      type: String
    }
  }
})
