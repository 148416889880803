



















import Vue from 'vue'

export default Vue.extend({
  props: {
    initialState: {
      required: true,
      type: Boolean
    }
  },

  data() {
    return {
      isEnabled: this.initialState
    }
  },

  watch: {
    isEnabled(newValue) {
      this.$emit('switched', newValue)
    }
  }
})
