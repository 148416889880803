/* eslint-disable import/no-mutable-exports */
import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import StakingStore from '~/store/staking'
import ModalStore from '~/store/modal'

let StakingStoreModule: StakingStore
let ModalStoreModule: ModalStore

function initializeModules(store: Store<any>): void {
  StakingStoreModule = getModule(StakingStore, store)
  ModalStoreModule = getModule(ModalStore, store)
}

export { initializeModules, ModalStoreModule, StakingStoreModule }
