import moment from 'moment'
import { BigNumber, BigNumberInBase } from '@injectivelabs/utils'
import { AccountAddress, Token } from '~/types'

BigNumber.config({
  FORMAT: {
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
    secondaryGroupSize: 0,
    fractionGroupSeparator: ' ',
    fractionGroupSize: 0
  }
})

export const formatWalletAddress = (address: AccountAddress): string => {
  if (address.length <= 10) {
    return address
  }

  return `${address.slice(0, 6)}...${address.slice(
    address.length - 6,
    address.length
  )}`
}

export function formatAmount(
  amount: BigNumberInBase,
  { displayDecimals }: Token
): string {
  return amount.toFormat(displayDecimals, BigNumber.ROUND_DOWN)
}

export function formatPrice(
  amount: BigNumberInBase,
  { displayDecimals }: Token
): string {
  return amount.toFormat(displayDecimals, BigNumber.ROUND_HALF_UP)
}

export function formatPriceUp(
  amount: BigNumberInBase,
  { displayDecimals }: Token
): string {
  return amount.toFormat(displayDecimals, BigNumber.ROUND_UP)
}

export function formatPriceDown(
  amount: BigNumberInBase,
  { displayDecimals }: Token
): string {
  return amount.toFormat(displayDecimals, BigNumber.ROUND_DOWN)
}

export function formatPrecision(amount: BigNumber, precision: number): string {
  return amount.toFormat(precision, BigNumber.ROUND_DOWN)
}

export function formatPercent({
  number,
  precision = 0,
  appendPlusSign = false
}: {
  number: any
  precision: number
  appendPlusSign: boolean
}): string {
  const numberInBigNumber =
    number instanceof BigNumber ? number : new BigNumber(number)
  const prefix = appendPlusSign && numberInBigNumber.isGreaterThan(0) ? '+' : ''
  const suffix = '%'

  return `${prefix}${String(numberInBigNumber.toFixed(precision))}${suffix}`
}

export function formatDateTime(time: number, _showMs: boolean = false): string {
  const date = moment.unix(time).local()

  return date.format('MM.DD HH:ss')
}

export const formatMarketIdToComplyToZeroEx = (marketId: string): string => {
  return `${marketId}00000000`
}

export const reverseFormatMarketIdToComplyToZeroEx = (
  marketId: string
): string => {
  return marketId.slice(0, marketId.length - 8)
}
