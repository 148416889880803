









import Vue from 'vue'

export default Vue.extend({
  props: {
    xs: {
      required: false,
      type: Boolean,
      default: false
    }
  }
})
