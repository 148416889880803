import { Module, VuexModule, config, Mutation } from 'vuex-module-decorators'
import { Modal, ModalStoreState, ModalState } from '~/types'

config.rawError = true

const modalValues = Object.values(Modal)
const modalExists = (modal: Modal) => modalValues.includes(modal)

const modals = modalValues.reduce((previous: ModalState, current: Modal) => {
  return { ...previous, [current]: false }
}, {} as ModalState)

const initialState: ModalStoreState = {
  modals
}

@Module({
  name: 'modal',
  stateFactory: true,
  namespaced: true
})
export default class ModalStore extends VuexModule implements ModalStoreState {
  modals: ModalState = initialState.modals

  @Mutation
  closeModal(modalToClose: Modal) {
    if (modalExists(modalToClose) && this.modals[modalToClose]) {
      this.modals = { ...this.modals, [modalToClose]: false }
    }
  }

  @Mutation
  toggleModal(modalToToggle: Modal) {
    if (modalExists(modalToToggle)) {
      this.modals = {
        ...this.modals,
        [modalToToggle]: !this.modals[modalToToggle]
      }
    }
  }

  @Mutation
  openModal(modalToOpen: Modal) {
    if (modalExists(modalToOpen)) {
      const modals: any = {}

      modalValues.forEach((modal) => {
        if (modal !== modalToOpen) {
          modals[modal] = false
        }
      })

      this.modals = { ...modals, [modalToOpen]: true }
    }
  }
}
