import { render, staticRenderFns } from "./unlocked.vue?vue&type=template&id=2a4e5c62&"
import script from "./unlocked.vue?vue&type=script&lang=ts&"
export * from "./unlocked.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports