import { Middleware, Context } from '@nuxt/types'
import { IS_PRODUCTION } from '~/app/utils/constants'

const preStakingDisabledRoutes = ['governance', 'proposals-proposal']

const preStakingMiddleware: Middleware = ({ redirect, route }: Context) => {
  const isProduction = IS_PRODUCTION

  if (isProduction && preStakingDisabledRoutes.includes(route.name as string)) {
    return redirect('/')
  }
}

export default preStakingMiddleware
