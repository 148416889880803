import { extend, localize } from 'vee-validate'
import {
  required,
  numeric,
  email,
  double,
  min,
  max,
  between
} from 'vee-validate/dist/rules'

localize({
  en: {
    messages: {
      required: 'This field is required',
      numeric: 'This field is a number',
      double: 'This field is a decimal number',
      injaddress:
        'The address must be a valid cosmos address (starts with "inj")',
      email: 'This field should be a valid email',
      between: 'This field should be between {min} and {max}',
      max: 'This field should be less than {length}',
      min: 'This field should be greater than {length}'
    }
  }
})

extend('injaddress', {
  validate: (value: string) => {
    return value.startsWith('inj')
  }
})
extend('required', required)
extend('double', double)
extend('numeric', numeric)
extend('email', email)
extend('between', between)
extend('min', min)
extend('max', max)
