import {
  CHAIN_ID,
  UI_DEFAULT_DECIMAL_PLACES,
  ZERO_ADDRESS
} from 'app/utils/constants'
import { contractAddresses } from '@injectivelabs/contracts'
import { Token, TokenAddressObject, TokenWithAddresses } from '~/types'

const baseTokens = [
  {
    symbol: 'INJ',
    name: 'Injective',
    icon: '/icons/inj-2.svg',
    decimals: 18,
    displayDecimals: 0,
    addresses: {
      '42': '',
      '888': ZERO_ADDRESS
    }
  }
]

export const includedInBalanceList = ['INJ']

const derivatives = [
  {
    symbol: 'USDT',
    name: 'USDT',
    icon: '/icons/usdt.svg',
    decimals: 6,
    displayDecimals: 4,
    addresses: {
      '42': '0x0ad43946F5D42f89fD099Ff10749D9892583DC2b',
      '888': contractAddresses['888'].baseCurrency
    }
  },
  ...baseTokens
]

export const transformedDerivatives = derivatives
  .filter(
    ({ addresses }: { addresses: TokenAddressObject }) => addresses[CHAIN_ID]
  )
  .map(
    ({
      symbol,
      addresses,
      decimals,
      name,
      icon,
      displayDecimals,
      quoteTokenDisplayDecimals
    }: TokenWithAddresses): Token => ({
      symbol,
      decimals,
      name,
      icon,
      addresses,
      address: addresses[CHAIN_ID],
      displayDecimals:
        displayDecimals || displayDecimals === 0
          ? displayDecimals
          : UI_DEFAULT_DECIMAL_PLACES,
      quoteTokenDisplayDecimals
    })
  )

export const USDT = {
  symbol: 'USDT',
  name: 'USDT',
  icon: '/icons/usdt.svg',
  decimals: 6,
  displayDecimals: 4,
  addresses: {
    '42': '0x69efCB62D98f4a6ff5a0b0CFaa4AAbB122e85e08',
    '888': contractAddresses['888'].baseCurrency
  },
  address: '0x69efCB62D98f4a6ff5a0b0CFaa4AAbB122e85e08'
}
