













import Vue from 'vue'

export default Vue.extend({
  props: {
    bg: {
      required: false,
      type: Boolean,
      default: true
    }
  }
})
