import { BigNumber } from '@injectivelabs/utils'
import { Change } from '~/types'

export * from './token'

export const getChangeFromBigNumberValues = (
  n1: BigNumber,
  n2: BigNumber
): Change => {
  if (!n1 || !n2) {
    return Change.NoChange
  }

  const diff = n1.minus(n2)

  if (diff.eq(0)) {
    return Change.NoChange
  }

  return diff.lt(0) ? Change.Increase : Change.Decrease
}

export const cosmosSdkDecToBigNumber = (
  number: string | number | BigNumber
): BigNumber => {
  return new BigNumber(number).dividedBy(new BigNumber(10).pow(18))
}

export const isNumeric = (str: string): boolean => {
  if (typeof str !== 'string') {
    return false
  }

  return (
    // @ts-ignore
    !isNaN(str) && !isNaN(parseFloat(str))
  )
}

export const getUniqueListBy = (arr: any[], key: string) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()]
}
