export enum NetworkId {
  Mainnet = 1,
  Rinkeby = 4,
  Kovan = 42,
  Testnet = 888
}

export enum Change {
  New = 'new',
  NoChange = 'no-change',
  Increase = 'increase',
  Decrease = 'decrease'
}

export enum Region {
  af = 'Africa',
  an = 'Antartica',
  as = 'Asia',
  eu = 'Europe',
  na = 'North America',
  oc = 'Oceania',
  sa = 'South America',
  Africa = 'af',
  Antartica = 'an',
  Asia = 'as',
  Europe = 'eu',
  NorthAmerica = 'na',
  Oceania = 'oc',
  SouthAmerica = 'sa'
}

export enum TokenType {
  Derivative = 'derivative',
  Token = 'token'
}

export enum Status {
  Idle = 'idle',
  Loading = 'loading',
  Completed = 'completed',
  Error = 'error',
  Confirmed = 'confirmed'
}

export enum Web3State {
  Idle = 'idle',
  Done = 'done',
  Error = 'error',
  Loading = 'loading',
  NotInstalled = 'not_installed',
  Locked = 'locked'
}

export enum TransactionMethod {
  EthSign = 'eth_sign',
  MetamaskEthSign = 'personal_sign',
  EthSignTypedData = 'eth_signTypedData_v3'
}

export enum Icon {
  Arrow = 'arrow',
  Copy = 'copy',
  Globe = 'globe',
  Discord = 'discord',
  Dropdown = 'dropdown',
  Info = 'info',
  Locked = 'locked',
  Metamask = 'metamask',
  Ledger = 'ledger',
  Reddit = 'reddit',
  Search = 'search',
  Trash = 'trash',
  Star = 'star',
  Sync = 'sync',
  Label = 'label',
  Telegram = 'telegram',
  Trending = 'trending',
  Twitter = 'twitter',
  Unlocked = 'unlocked',
  Wallet = 'wallet',
  CloseCircle = 'close-circle',
  ExternalLink = 'external-link',
  Sort = 'sort'
}

export enum Modal {
  Wallet = 'Wallet',
  Delegate = 'Delegate'
}
