import { LocalStorage } from '@injectivelabs/utils'

let localStorage: LocalStorage
export const getStorage = (): LocalStorage => {
  if (!localStorage) {
    localStorage = new LocalStorage(
      `inj-v5-${process.env.APP_ENV || 'staking'}`
    )
  }

  return localStorage
}
