
import Vue, { PropType, VNode } from 'vue'
import { formatAmount } from 'app/utils/formatters'
import { BigNumberInBase } from '@injectivelabs/utils'
import { Token } from '~/types'

export default Vue.extend({
  props: {
    prefix: {
      required: false,
      type: String,
      default: ''
    },

    suffix: {
      required: false,
      type: String,
      default: ''
    },

    value: {
      required: true,
      type: Object as PropType<BigNumberInBase>
    },

    greyZeros: {
      required: false,
      default: false,
      type: Boolean
    },

    token: {
      required: true,
      type: Object as PropType<Token>
    }
  },

  computed: {
    formatFunction(): Function {
      return formatAmount
    },

    formattedValue(): any {
      return this.formatFunction(this.value, this.token)
    }
  },

  render(createElement): VNode {
    const parentAttributes = {
      attrs: this.$attrs,
      on: this.$listeners,
      class: 'font-mono'
    }

    if (this.greyZeros) {
      return createElement('v-ui-format-number', {
        ...parentAttributes,
        props: {
          formatter: this.formatFunction,
          token: this.token,
          value: this.value,
          suffix: this.suffix,
          prefix: this.prefix
        }
      })
    }

    return createElement('span', parentAttributes, [this.formattedValue])
  }
})
