import {
  transformedDerivatives,
  includedInBalanceList as includedDerivativeTokens
} from 'app/data/derivatives'
import { Token, TokenAddress, TokenSymbol } from '~/types'

export class Derivative {
  protected readonly tokens: Token[] = []

  constructor() {
    this.tokens = transformedDerivatives
  }

  getTokenBySymbol(symbol: TokenSymbol): Token {
    if (!symbol) {
      throw new Error(`${symbol} is not a valid value.`)
    }

    const symbolInLowerCaseScore = symbol.toLowerCase()
    const token = this.tokens.find(
      ({ symbol }) => symbol.toLowerCase() === symbolInLowerCaseScore
    )

    if (!token) {
      throw new Error(`Token with symbol ${symbol} not found in known tokens`)
    }

    return token
  }

  tokenWithSymbolExists(symbol: TokenSymbol): boolean {
    if (!symbol) {
      return false
    }

    const symbolInLowerCaseScore = symbol.toLowerCase()
    const token = this.tokens.find(
      ({ symbol }) => symbol.toLowerCase() === symbolInLowerCaseScore
    )

    if (!token) {
      return false
    }

    return true
  }

  tokenWithAddressExists(address: TokenAddress): boolean {
    const token = this.tokens.find(
      (t) => t.address.toLowerCase() === address.toLowerCase()
    )

    if (!token) {
      return false
    }

    return true
  }

  getTokenByAddress(address: TokenAddress): Token {
    const token = this.tokens.find(
      (t) => t.address.toLowerCase() === address.toLowerCase()
    )

    if (!token) {
      throw new Error(`Token with address ${address} not found in known tokens`)
    }

    return token
  }

  getTokensListForBalance(): Token[] {
    return this.tokens.filter(({ symbol }: Token) => {
      return includedDerivativeTokens.includes(symbol)
    })
  }

  getTokens(): Token[] {
    return this.tokens
  }
}

let $derivatives: Derivative
export const getDerivatives = (): Derivative => {
  if (!$derivatives) {
    $derivatives = new Derivative()
  }

  return $derivatives
}
