import {
  Network,
  UrlEndpoint,
  getUrlEndpointForNetwork
} from '@injectivelabs/networks'
import { ChainId } from '@injectivelabs/ts-types'
import { Wallet } from '@injectivelabs/web3-strategy'
import { getStorage } from './Storage'

class App {
  network: Network
  chainId: ChainId

  constructor() {
    this.chainId =
      ((process.env.APP_CHAIN_ID as unknown) as ChainId) || ChainId.Injective
    this.network = (process.env.APP_NETWORK as Network) || Network.Local
  }

  get appUrlEndpoint(): UrlEndpoint {
    return getUrlEndpointForNetwork(this.network)
  }

  get wallet(): Wallet {
    const existingState = getStorage().get('state') as any

    return (
      (existingState.staking && existingState.staking.wallet) || Wallet.Metamask
    )
  }
}

export const app = new App()
