import { BigNumber } from '@injectivelabs/utils'
import {
  DEFAULT_ORDER_EXPIRY_SECONDS,
  SECONDS_IN_A_DAY
} from 'app/utils/constants'
import moment from 'moment'

export const tomorrow = (): BigNumber => {
  return new BigNumber(Math.floor(new Date().valueOf() / 1000) + 3600 * 24)
}

export const todayInSeconds = (): number => {
  return Math.floor(Date.now() / 1000)
}

export const past24Hours = (): number => {
  return new BigNumber(todayInSeconds()).minus(SECONDS_IN_A_DAY).toNumber()
}

export const pastDays = (day = 1): number => {
  return new BigNumber(todayInSeconds())
    .minus(SECONDS_IN_A_DAY.times(day))
    .toNumber()
}

export const unixToDate = (timestamp: number): string => {
  return moment.unix(timestamp).format('YYYY-MM-DD')
}

export const getExpirationTradeTimeFromConfig = () => {
  return new BigNumber(todayInSeconds()).plus(DEFAULT_ORDER_EXPIRY_SECONDS)
}

export const getEndDateStringFromTimeInSeconds = (
  timeInSeconds: BigNumber
): string => {
  const currentDate = new Date(timeInSeconds.toNumber() * 1000)

  return currentDate.toLocaleString('en-us')
}

export const getUnixTimes = (value: any, unit: any, startOf = 'hour') => [
  // @ts-ignore
  moment().startOf(startOf).subtract(value, unit).unix(),
  // @ts-ignore
  moment().startOf(startOf).add(3, 'minute').unix()
]
