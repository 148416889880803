import {
  Commission,
  Description,
  RedelegationResponse
} from '@injectivelabs/chain-api/cosmos/staking/v1beta1/staking_pb'
import lodash from 'lodash'
import { BigNumberInBase, BigNumberInWei } from '@injectivelabs/utils'
import {
  STAKING_RATIO,
  TESTNET_CHAIN_ID,
  ZERO_IN_WEI
} from 'app/utils/constants'
import {
  GrpcCoin,
  GrpcDelegationDelegatorReward,
  GrpcProposal,
  GrpcProposalDeposit,
  GrpcTallyResult,
  GrpcVote,
  ExchangeProposalDecomposer,
  GovernanceProposalDecomposer,
  GrpcSpotMarket
} from '@injectivelabs/chain-consumer'
import { BaseCurrencyContract } from '@injectivelabs/contracts/dist/contracts/BaseCurrency'
import {
  MarketStatus,
  MarketStatusMap
} from '@injectivelabs/chain-api/injective/exchange/v1beta1/exchange_pb'
import { OracleTypeMap } from '@injectivelabs/chain-api/injective/oracle/v1beta1/oracle_pb'
import { getWeb3Strategy } from '../web3'
import { cosmosSdkDecToBigNumber } from '.'
import {
  BondStatus,
  GrpcValidator,
  GrpcInsuranceFund,
  UiValidator,
  Validator,
  ValidatorDescription,
  ValidatorCommission,
  GrpcDelegationResponse,
  UiDelegator,
  NetworkStatus,
  UiNetworkStatus,
  BasicData,
  UiBasicData,
  GrpcUnBondingDelegation,
  UiUnBondingDelegator,
  ProposalStatus,
  UiProposal,
  UiGovParams,
  UiReward,
  UiCoin,
  UiSingleProposal,
  VoteOptionNumber,
  VoteOption,
  UiSupplyCoin,
  UiProposalDeposit,
  UiSpotMarket,
  UiMarketStatus,
  UiReDelegator,
  UiInsuranceFund,
  UiOracleType,
  Token,
  GrpcOracle,
  UiOracle
} from '~/types'

export const govParamsToUiGovParams = ({
  votingParams,
  depositParams,
  tallyParams
}: {
  votingParams: any
  depositParams: any
  tallyParams: any
}): UiGovParams => {
  const minDeposits = depositParams?.getMinDepositList().map((deposit: any) => {
    return {
      denom: deposit.getDenom(),
      amount: deposit.getAmount()
    }
  })
  const minInjDeposit = minDeposits?.find(
    (deposit: any) => deposit.denom === 'inj'
  )!
  const quorum = tallyParams?.getQuorum() || ''
  const threshold = tallyParams?.getThreshold() || ''
  const vetoThreshold = tallyParams?.getVetoThreshold() || ''

  return {
    votingParams: {
      votingPeriod: votingParams
        ? votingParams.getVotingPeriod().getSeconds()
        : 0
    },
    depositParams: {
      minInjDeposit,
      minDeposits: minDeposits || [],
      maxDepositPeriod: depositParams
        ? depositParams.getMaxDepositPeriod().getSeconds()
        : 0
    },
    tallyParams: {
      quorum: Uint8ArrayToBigNumber(quorum).toFixed(),
      threshold: Uint8ArrayToBigNumber(threshold).toFixed(),
      vetoThreshold: Uint8ArrayToBigNumber(vetoThreshold).toFixed()
    }
  }
}

export const Uint8ArrayToBigNumber = (value: any) => {
  return cosmosSdkDecToBigNumber(
    typeof value === 'object' && value.constructor === Uint8Array
      ? new TextDecoder().decode(value)
      : value
  )
}

export const proposalsToUiProposals = (
  grpcProposals: GrpcProposal[]
): UiProposal[] => {
  return grpcProposals
    .map((grpcProposal: GrpcProposal) => {
      const finalTallyResult = grpcProposal.getFinalTallyResult()
      const content = grpcProposal.getContent()
      return {
        proposalId: grpcProposal.getProposalId(),
        content: {
          type: content.getTypeName(),
          value: getProposalContentBasedOnType(
            content.getTypeName(),
            content.getValue()
          )
        },
        submitTime: grpcProposal.getSubmitTime().getSeconds(),
        status: grpcProposalStatusToUiStatus(grpcProposal.getStatus()),
        finalTallyResult: {
          yes: finalTallyResult?.getYes() || '0',
          abstain: finalTallyResult?.getAbstain() || '0',
          no: finalTallyResult?.getNo() || '0',
          noWithVeto: finalTallyResult?.getNoWithVeto() || '0'
        },
        depositEndTime: grpcProposal.getDepositEndTime().getSeconds(),
        totalDeposits: grpcProposal.getTotalDepositList().map((coin) => ({
          denom: coin.getDenom(),
          amount: cosmosSdkDecToBigNumber(coin.getAmount()).toFixed()
        })),
        votingStartTime: grpcProposal.getVotingStartTime().getSeconds(),
        votingEndTime: grpcProposal.getVotingEndTime().getSeconds()
      }
    })
    .sort((p1, p2) => p2.submitTime - p1.submitTime)
}

export const getProposalContentBasedOnType = (
  type: string,
  content: Uint8Array
) => {
  switch (type) {
    case 'injective.exchange.v1beta1.SpotMarketLaunchProposal':
      return ExchangeProposalDecomposer.spotMarketLaunch(content).toObject()
    case 'injective.exchange.v1beta1.PerpetualMarketLaunchProposal':
      return ExchangeProposalDecomposer.perpetualMarketLaunch(
        content
      ).toObject()
    case 'injective.exchange.v1beta1.ExpiryFuturesMarketLaunchProposal':
      return ExchangeProposalDecomposer.expiryFuturesMarketLaunch(
        content
      ).toObject()
    case 'injective.exchange.v1beta1.SpotMarketParamUpdateProposal':
      return ExchangeProposalDecomposer.spotMarketUpdate(content).toObject()
    case 'cosmos.params.v1beta1.ParameterChangeProposal':
      return GovernanceProposalDecomposer.parametersChange(content).toObject()
    default:
      return ExchangeProposalDecomposer.spotMarketLaunch(content).toObject()
  }
}

export const validatorsToUiValidators = (
  grpcValidators: GrpcValidator[],
  _delegators: GrpcDelegationResponse[][]
): UiValidator[] => {
  return grpcValidators.map((grpcValidator: GrpcValidator) => {
    const validator = grpcValidatorToValidator(grpcValidator)
    const stakedAmount = new BigNumberInBase(validator.delegatorShares).toWei()
    const selfDelegation = new BigNumberInWei(stakedAmount.minus(ZERO_IN_WEI))

    return {
      stakedAmount,
      selfDelegation: ZERO_IN_WEI,
      jailed: validator.jailed,
      status: validator.status,
      unBondingTime: validator.unBondingTime.getSeconds(),
      unBondingHeight: validator.unBondingHeight,
      delegatedAmount: stakedAmount.minus(selfDelegation),
      commissionRate: new BigNumberInBase(
        validator.commission.commissionRates.rate
      ),
      name: validator.description.moniker,
      address: validator.operatorAddress,
      delegators: []
    } as UiValidator
  })
}

export const insuranceFundToUiInsuranceFund = (
  grpcInsuranceFund: GrpcInsuranceFund
): UiInsuranceFund => {
  const redemptionNoticePeriodDuration =
    grpcInsuranceFund.getRedemptionNoticePeriodDuration()

  return {
    depositDenom: grpcInsuranceFund.getDepositDenom(),
    insurancePoolTokenDenom: grpcInsuranceFund.getPoolTokenDenom(),
    redemptionNoticePeriodDuration: redemptionNoticePeriodDuration || 0,
    balance: grpcInsuranceFund.getBalance(),
    totalShare: grpcInsuranceFund.getTotalShare(),
    depositTokenMeta:
      grpcInsuranceFund.getDepositTokenMeta() as unknown as Token,
    marketId: grpcInsuranceFund.getMarketId(),
    marketTicker: grpcInsuranceFund.getMarketTicker(),
    oracleBase: grpcInsuranceFund.getOracleBase(),
    oracleQuote: grpcInsuranceFund.getOracleQuote(),
    oracleType: grpcInsuranceFund.getOracleType() as UiOracleType,
    expiry: grpcInsuranceFund.getExpiry()
  }
}

export const insuranceFundsToUiInsuranceFunds = (
  grpcInsuranceFunds: GrpcInsuranceFund[]
): UiInsuranceFund[] => {
  return grpcInsuranceFunds.map((grpcInsuranceFund) =>
    insuranceFundToUiInsuranceFund(grpcInsuranceFund)
  )
}

export const oracleToUiOracle = (grpcOracle: GrpcOracle): UiOracle => {
  return {
    symbol: grpcOracle.getSymbol(),
    baseSymbol: grpcOracle.getBaseSymbol(),
    quoteSymbol: grpcOracle.getQuoteSymbol(),
    oracleType: grpcOracle.getOracleType() as UiOracleType,
    price: grpcOracle.getPrice()
  }
}

export const oraclesToUiOracles = (grpcOracles: GrpcOracle[]): UiOracle[] => {
  return grpcOracles.map((grpcOracle) => oracleToUiOracle(grpcOracle))
}

export const grpcOracleTypeStatusToUiOracleTypeStatus = (
  oracleType: OracleTypeMap[keyof OracleTypeMap]
): UiOracleType => {
  switch (oracleType) {
    case UiOracleType.UNSPECIFIED:
      return UiOracleType.Unspecified
    case UiOracleType.BAND:
      return UiOracleType.Band
    case UiOracleType.COINBASE:
      return UiOracleType.Coinbase
    case UiOracleType.PRICEFEED:
      return UiOracleType.Pricefeed
    case UiOracleType.RAZOR:
      return UiOracleType.Razor
    case UiOracleType.DIA:
      return UiOracleType.Dia
    case UiOracleType.API3:
      return UiOracleType.Api3
    case UiOracleType.UMA:
      return UiOracleType.Uma
    case UiOracleType.PYTH:
      return UiOracleType.Pyth
    case UiOracleType.BANDIBC:
      return UiOracleType.Bandibc
    default:
      return UiOracleType.Pricefeed
  }
}

export const grpcMarketStatusToUiMarketStatus = (
  status: MarketStatusMap[keyof MarketStatusMap]
): UiMarketStatus => {
  switch (status) {
    case MarketStatus.ACTIVE:
      return UiMarketStatus.Active
    case MarketStatus.PAUSED:
      return UiMarketStatus.Paused
    case MarketStatus.DEMOLISHED:
      return UiMarketStatus.Demolished
    case MarketStatus.SUSPENDED:
      return UiMarketStatus.Suspended
    case MarketStatus.EXPIRED:
      return UiMarketStatus.Expired
    default:
      return UiMarketStatus.Active
  }
}

export const spotMarketToUiSpotMarket = (
  grpcSpotMarkets: GrpcSpotMarket[]
): UiSpotMarket[] => {
  return grpcSpotMarkets.map((grpcMarket: GrpcSpotMarket) => {
    return {
      ticker: grpcMarket.getTicker(),
      baseDenom: grpcMarket.getBaseDenom(),
      quoteDenom: grpcMarket.getQuoteDenom(),
      makerFeeRate: grpcMarket.getMakerFeeRate(),
      takerFeeRate: grpcMarket.getTakerFeeRate(),
      relayerFeeShareRate: grpcMarket.getRelayerFeeShareRate(),
      marketId: grpcMarket.getMarketId(),
      status: grpcMarketStatusToUiMarketStatus(grpcMarket.getStatus()),
      minPriceTickSize: grpcMarket.getMinPriceTickSize(),
      minQuantityTickSize: grpcMarket.getMinQuantityTickSize()
    }
  })
}

export const grpcValidatorToValidator = (
  validator: GrpcValidator
): Validator => {
  return {
    operatorAddress: validator.getOperatorAddress(),
    consensusPubKey: validator.getConsensusPubkey(),
    jailed: validator.getJailed(),
    status: grpcValidatorStatusToUiStatus(validator.getStatus()),
    tokens: new BigNumberInWei(
      new BigNumberInWei(validator.getTokens())
        .toBase()
        .dividedBy(STAKING_RATIO)
    ),
    delegatorShares: new BigNumberInWei(
      new BigNumberInWei(validator.getDelegatorShares())
        .toBase()
        .dividedBy(STAKING_RATIO)
    ),
    description: grpcValidatorDescriptionToUiDescription(
      validator.getDescription()
    ),
    unBondingHeight: validator.getUnbondingHeight(),
    unBondingTime: validator.getUnbondingTime(),
    commission: grpcValidatorCommissionToUiCommission(
      validator.getCommission()
    ),
    minSelfDelegation: new BigNumberInWei(validator.getMinSelfDelegation())
  }
}

export const grpcDelegatorsToUiDelegators = (
  grpcDelegators: GrpcDelegationResponse[]
): UiDelegator[] => {
  return grpcDelegators
    .map((grpcDelegator) => {
      const delegation = grpcDelegator.getDelegation()
      const balance = grpcDelegator.getBalance()

      return {
        delegation: {
          delegatorAddress: delegation ? delegation.getDelegatorAddress() : '',
          validatorAddress: delegation ? delegation.getValidatorAddress() : '',
          shares: new BigNumberInWei(
            new BigNumberInWei(delegation ? delegation.getShares() : 0)
              .toBase()
              .dividedBy(STAKING_RATIO)
          )
        },
        balance: {
          denom: balance ? balance.getDenom() : '',
          amount: new BigNumberInWei(balance ? balance.getAmount() : 0).toBase()
        }
      }
    })
    .filter((d) => d.balance.amount.gte(0.01))
}

export const grpcDelegationRewardToUiReward = (
  grpcRewards: GrpcDelegationDelegatorReward[]
): UiReward[] => {
  return grpcRewards.map((grpcReward) => {
    const rewards = grpcReward.getRewardList().map((reward) => ({
      amount: cosmosSdkDecToBigNumber(reward.getAmount()).toFixed(),
      denom: reward.getDenom()
    }))

    return {
      rewards,
      validatorAddress: grpcReward.getValidatorAddress()
    }
  })
}

export const grpcUnBondingDelegatorsToUiUnBondingDelegators = (
  grpcUnBondingDelegations: GrpcUnBondingDelegation[]
): UiUnBondingDelegator[] => {
  return grpcUnBondingDelegations
    .reduce((unbondingDelegations, grpcUnBondingDelegation) => {
      const entries = grpcUnBondingDelegation.getEntriesList()
      const mappedEntries = entries.map((entry) => ({
        delegatorAddress: grpcUnBondingDelegation
          ? grpcUnBondingDelegation.getDelegatorAddress()
          : '',
        validatorAddress: grpcUnBondingDelegation
          ? grpcUnBondingDelegation.getValidatorAddress()
          : '',
        creationHeight: entry.getCreationHeight(),
        completionTime: entry.getCompletionTime().getSeconds(),
        initialBalance: new BigNumberInWei(entry.getInitialBalance()),
        balance: new BigNumberInWei(entry.getBalance())
      }))

      return [...unbondingDelegations, ...mappedEntries]
    }, [] as UiUnBondingDelegator[])
    .filter((d) => d.balance.gte(0.01))
}

export const grpcReDelegatorsToUiReDelegators = (
  grpcReDelegations: RedelegationResponse[]
): UiReDelegator[] => {
  return grpcReDelegations.reduce((uiReDelegator, grpcReDelegation) => {
    const grpcRedelegation = grpcReDelegation.getRedelegation()!
    const entries = grpcReDelegation.getEntriesList()

    if (!grpcReDelegation) {
      return uiReDelegator
    }

    const uiRedelegations = entries.reduce((acc, redelegation) => {
      const entry = redelegation.getRedelegationEntry()

      return [
        ...acc,
        {
          delegation: {
            completionTime: entry ? entry.getCompletionTime().getSeconds() : 0,
            delegatorAddress: grpcRedelegation.getDelegatorAddress() || '',
            sourceValidatorAddress:
              grpcRedelegation.getValidatorSrcAddress() || '',
            destinationValidatorAddress:
              grpcRedelegation?.getValidatorDstAddress() || ''
          },
          balance: new BigNumberInWei(redelegation.getBalance()).toBase()
        }
      ]
    }, [] as UiReDelegator[])

    return [...uiReDelegator, ...uiRedelegations]
  }, [] as UiReDelegator[])
}

export const grpcValidatorStatusToUiStatus = (status: number) => {
  switch (status) {
    case 1:
      return BondStatus.UnBonded
    case 2:
      return BondStatus.UnBonding
    case 3:
      return BondStatus.Bonded
    default:
      return BondStatus.UnBonded
  }
}

export const grpcProposalStatusToUiStatus = (status: number) => {
  switch (status) {
    case ProposalStatus.PROPOSAL_STATUS_UNSPECIFIED:
      return ProposalStatus.Unspecified
    case ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD:
      return ProposalStatus.DepositPeriod
    case ProposalStatus.PROPOSAL_STATUS_PASSED:
      return ProposalStatus.Passed
    case ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD:
      return ProposalStatus.VotingPeriod
    case ProposalStatus.PROPOSAL_STATUS_REJECTED:
      return ProposalStatus.Rejected
    case ProposalStatus.PROPOSAL_STATUS_FAILED:
      return ProposalStatus.Failed
    default:
      return ProposalStatus.Failed
  }
}

export const grpcValidatorDescriptionToUiDescription = (
  description?: Description
): ValidatorDescription => {
  return {
    moniker: description ? description.getMoniker() : '',
    identity: description ? description.getIdentity() : '',
    website: description ? description.getWebsite() : '',
    securityContact: description ? description.getSecurityContact() : '',
    details: description ? description.getDetails() : ''
  }
}

export const grpcValidatorCommissionToUiCommission = (
  commission?: Commission
): ValidatorCommission => {
  const commissionRates = commission ? commission.getCommissionRates() : null

  return {
    commissionRates: {
      rate: new BigNumberInWei(
        commissionRates ? commissionRates.getRate() : 0
      ).toBase(),
      maxRate: new BigNumberInWei(
        commissionRates ? commissionRates.getMaxRate() : 0
      ).toBase(),
      maxChangeRate: new BigNumberInWei(
        commissionRates ? commissionRates.getMaxChangeRate() : 0
      ).toBase()
    },

    updateTime: commission ? commission.getUpdateTime() : new Date()
  }
}

export const networkStatusToUiNetworkStatus = (
  networkStatus: NetworkStatus
): UiNetworkStatus => {
  return {
    chainId: networkStatus.chain_id,
    blockTime: networkStatus.block_time * 1000,
    latestBlockHeight: networkStatus.latest_block_height,
    totalValidatorNum: networkStatus.total_validator_num,
    timestamp: networkStatus.timestamp
  }
}

export const grpcCoinsSupplyToUiCoins = async (
  grpcCoins: GrpcCoin[]
): Promise<UiSupplyCoin[]> => {
  const coins = await Promise.all(
    grpcCoins.map(async (coin) => {
      const denom = coin.getDenom()

      return {
        denom,
        mappedDenom: {
          label: (await getGrpcCoinDenomFromPeggyAddress(coin)).toUpperCase(),
          code: denom
        },
        amount: cosmosSdkDecToBigNumber(coin.getAmount()).toFixed()
      }
    })
  )

  const disabled = ['TENJ', 'MAX', 'USDT']
  const usdt = coins.find((c) => {
    return (
      c.denom.toLowerCase() ===
      `peggy0x69efcb62d98f4a6ff5a0b0cfaa4aabb122e85e08`.toLowerCase()
    )
  })!

  const nonDuplicatedCoins = coins
    .filter((c) => c.denom && !disabled.includes(c.mappedDenom.label))
    .reduce((acc, current) => {
      const x = acc.find(
        (item) => item.mappedDenom.label === current.mappedDenom.label
      )
      if (!x) {
        return acc.concat([current])
      } else {
        return acc
      }
    }, [] as UiSupplyCoin[])

  return [usdt, ...nonDuplicatedCoins]
}

export const grpcCoinsToUiCoins = (grpcCoins: GrpcCoin[]): UiCoin[] => {
  return grpcCoins.map((coin) => {
    return {
      denom: coin.getDenom(),
      amount: cosmosSdkDecToBigNumber(coin.getAmount()).toFixed()
    }
  })
}

export const getGrpcCoinDenomFromPeggyAddress = async (
  coin: GrpcCoin
): Promise<string> => {
  const denom = coin.getDenom()

  if (!denom.startsWith('peggy')) {
    return denom
  }

  const contractAddress = denom.replace('peggy', '')
  const web3Strategy = getWeb3Strategy()

  try {
    const erc20Contract = new BaseCurrencyContract({
      web3Strategy,
      address: contractAddress,
      chainId: TESTNET_CHAIN_ID
    })

    return await erc20Contract.getSymbol().callAsync()
  } catch (e) {
    return ''
  }
}

export const proposalToUiProposal = ({
  grcpProposal,
  grpcProposalDeposits,
  grpcProposalVotes,
  grpcProposalTally
}: {
  grcpProposal: GrpcProposal
  grpcProposalDeposits: GrpcProposalDeposit[]
  grpcProposalVotes: GrpcVote[]
  grpcProposalTally: GrpcTallyResult | undefined
}): UiSingleProposal => {
  const [uiProposal] = proposalsToUiProposals([grcpProposal])
  const deposits = grpcProposalDeposits.map((deposit): UiProposalDeposit => {
    return {
      depositor: deposit.getDepositor(),
      amounts: deposit.getAmountList().map((coin) => ({
        denom: coin.getDenom(),
        amount: cosmosSdkDecToBigNumber(coin.getAmount()).toFixed()
      }))
    }
  })

  return {
    ...uiProposal,
    votes: grpcProposalVotes.map((v) => ({
      proposalId: uiProposal.proposalId,
      voter: v.getVoter(),
      option: grpcVoteOptionToUiVoteOption(v.getOption())
    })),
    deposits,
    tallyResult: {
      yes: grpcProposalTally ? grpcProposalTally.getYes() : '0',
      abstain: grpcProposalTally ? grpcProposalTally.getAbstain() : '0',
      no: grpcProposalTally ? grpcProposalTally.getAbstain() : '0',
      noWithVeto: grpcProposalTally ? grpcProposalTally.getNoWithVeto() : '0'
    }
  }
}

export const grpcVoteOptionToUiVoteOption = (voteOption: VoteOptionNumber) => {
  switch (voteOption) {
    case VoteOptionNumber.VOTE_OPTION_UNSPECIFIED:
      return VoteOption.Unspecified
    case VoteOptionNumber.VOTE_OPTION_YES:
      return VoteOption.Yes
    case VoteOptionNumber.VOTE_OPTION_NO:
      return VoteOption.No
    case VoteOptionNumber.VOTE_OPTION_ABSTAIN:
      return VoteOption.Abstain
    case VoteOptionNumber.VOTE_OPTION_NO_WITH_VETO:
      return VoteOption.NoWithVeto
    default:
      return VoteOption.Unspecified
  }
}

export const basicDataToUiBasicData = (basicData: BasicData): UiBasicData => {
  return {
    currency: basicData.currency,
    price: basicData.current_price,
    marketCap: basicData.market_cap,
    vol24h: basicData.total_volume,
    change24h: basicData.percent_change_24h,
    lastUpdatedAt: basicData.last_updated_at
  }
}

export const chartDataToUiChartData = (chartData: any) => {
  const mapped = lodash.map(['prices', 'total_volumes'], (key) =>
    lodash.map(chartData[key], (v) => [v[0], Math.round(v[1] * 100) / 100])
  )

  return lodash.map(mapped, (arr) =>
    lodash.filter(
      arr,
      (_v, idx) => idx % 4 === 0 || idx === 0 || idx === mapped.length - 1
    )
  )
}
