













import Vue, { PropType } from 'vue'
import { BigNumberInBase } from '@injectivelabs/utils'
import { formatAmount } from 'app/utils/formatters'
import { Token } from '~/types'

export default Vue.extend({
  props: {
    prefix: {
      required: false,
      type: String,
      default: ''
    },

    suffix: {
      required: false,
      type: String,
      default: ''
    },

    value: {
      required: true,
      type: Object as PropType<BigNumberInBase>
    },

    token: {
      required: true,
      type: Object as PropType<Token>
    },

    dontGroupValues: {
      required: false,
      default: false,
      type: Boolean
    }
  },

  computed: {
    formattedNumber(): string[] {
      const formatted = formatAmount(this.value, this.token)

      if (this.dontGroupValues) {
        return [formatted]
      }

      const match = formatted.match(/^-?([\d,]+)((\.)(\d+?\d+?)(0*))?$/)
      const groups = !match
        ? formatted
          ? [formatted]
          : []
        : match[2]
        ? [`${match[1]}${match[3]}${match[4]}`, match[5]]
        : [`${match[1]}.0`]

      return groups
    }
  }
})
