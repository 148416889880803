import {
  BigNumber,
  BigNumberInBase,
  BigNumberInWei
} from '@injectivelabs/utils'
import { TokenSymbol } from '~/types'

export const tokenAmountInWeiToBigNumber = (
  amount: any,
  decimals: number
): BigNumberInWei => {
  const amountInBigNumber =
    amount instanceof BigNumber ? amount : new BigNumber(amount)
  const decimalsPerToken = new BigNumber(10).pow(decimals)

  return new BigNumberInWei(amountInBigNumber.div(decimalsPerToken))
}

export const tokenAmountInWei = (
  amount: any,
  decimals: number,
  displayDecimals: number = 2
) => {
  const amountInBigNumber =
    amount instanceof BigNumber ? amount : new BigNumber(amount)

  return tokenAmountInWeiToBigNumber(amountInBigNumber, decimals).toFormat(
    displayDecimals
  )
}

export const unitsInTokenAmount = (
  units: any,
  decimals: number
): BigNumberInBase => {
  const unitsInBigNumber =
    units instanceof BigNumber ? units : new BigNumber(units)

  const decimalsPerToken = new BigNumber(10).pow(decimals)

  return new BigNumberInBase(unitsInBigNumber.multipliedBy(decimalsPerToken))
}

export const isWeth = (symbol: string) => {
  return symbol.toLowerCase() === 'weth'
}

export const isEth = (symbol: string) => {
  return symbol.toLowerCase() === 'eth'
}

export const tokenSymbolToDisplayString = (symbol: TokenSymbol): string => {
  return symbol.toLocaleLowerCase() === 'weth' ? 'WETH' : symbol.toUpperCase()
}
