export default {
  staking: {
    injective: 'Injective',
    injective_staking: 'Injective Staking',
    connected: 'Connected',
    wallet: 'Wallet',
    staking: 'Staking',
    rewards_calculator: 'Rewards Calculator',
    copy_address: 'Copy Address',

    start_your_journey: 'Start your journey with the Injective Protocol!',
    need_a_wallet: 'Need a wallet?',
    connect_your_wallet: 'Connect to your wallet',
    search_validators: 'Search a validator',
    tokens: 'Tokens',
    token: 'Token',
    amount: 'Amount',

    validator: 'Validator',
    validators: 'Validators',
    staked_amount: 'Staked Amount',
    commission_rate: 'Commission Rate',
    delegated_amount: 'Delegated Amount',
    self_delegation: 'Self Delegation',
    commission_rate_tooltip:
      'The commission charged by validators for helping to secure the network.',
    self_delegation_tooltip: 'Delegated Amount',
    total_reward: 'Total Reward',
    total_reward_tooltip:
      'The total INJ rewards you have earned from delegating to a validator.',
    actions: 'Actions',
    logout: 'Logout',
    transfer: 'Transfer',
    successfully_transferred: 'Successfully Transferred',
    set_allowance: 'Approve transfer of ERC20 tokens into Injective',
    set_allowance_note:
      'In order for you to deposit on our exchange, please approve the transfer of your ERC20 tokens by clicking on the toggle below. You need to do this one time.',
    staking_note:
      'Staking will be subject to a 7 days time lock schedule. This means that you will not be able to withdraw your tokens until 7 days later. This is used to simulate the unbonding period on mainnet.',
    pre_staking_note:
      'Pre-Staking will be subject to a 7 days time lock schedule. This means that you will not be able to withdraw your tokens until 7 days later. This is used to simulate the unbonding period on mainnet.',
    withdrawal_note: 'You will be able to withdraw {time}',
    pre_staking_confirmation: 'Pre-Staking Confirmation',
    amount_to_stake: 'Amount to Stake',
    amount_to_pre_stake: 'Amount to Pre-Stake',
    after_staking:
      'Please wait a few minutes for your stake to appear since the transaction must be confirmed in the Ethereum network.',
    before_allowance:
      'Please wait a few minutes for your allowance since the transaction must be confirmed in the Ethereum network.',
    copy_address_done: 'Address Copied',
    delegate: 'Delegate',
    delegate_to: 'Delegate to {validator}',
    successfully_staked: 'Successfully staked!',
    successfully_unBond: 'Successfully unbonded',
    destination_address: 'Destination Address',
    token_unlock_successful: 'Token Unlocked',
    token_lock_successful: 'Token Locked',
    available_balance: 'Available Balance',
    address: 'Address',
    validator_status: 'Status',
    validator_status_tooltip:
      "This denotes the validator status. A validator can be 'Active', 'Unbonding', or 'Unbonded'. Users can only delegate to active validators.",
    active_only: 'Active Only',
    unBonding_amount: 'Unbonding Amount',
    unBonding_amount_tooltip: 'Unbonding Amount',
    time_remaining: 'Time Remaining',
    time_remaining_tooltip:
      'The period during which no rewards are handed out. After this period ends, you will get your delegation back to your balance.',
    days: 'Days',
    successfully_subscribed: 'Successfully joined our waiting list!',
    join_waitlist: 'Join Our Wait List',
    email: 'Email',
    join: 'Join',
    email_exists: 'Email Exists',
    governance: 'Governance',
    stake: 'Stake',
    withdraw: 'Withdraw',
    'proposal-spot-market-launch': 'Launch a spot market',
    'proposal-perpetual-market-launch': 'Launch a perpetual market',
    'proposal-expiry-futures-market-launch': 'Launch a expiry futures market',
    'proposal-spot-market-update': 'Update a spot market',
    'proposal-spot-market-status-update': 'Update a status on a spot market',

    // Proposals
    title: 'Title',
    description: 'Description',
    ticker: 'Ticker',
    base_denom: 'Base Denom',
    quote_denom: 'Quote Denom',
    propose: 'Propose',
    successfully_proposed: 'Successfully proposed',
    'proposal-status-PROPOSAL_STATUS_UNSPECIFIED': 'Unspecified',
    'proposal-status-PROPOSAL_STATUS_DEPOSIT_PERIOD': 'Deposit',
    'proposal-status-PROPOSAL_STATUS_VOTING_PERIOD': 'Voting',
    'proposal-status-PROPOSAL_STATUS_PASSED': 'Passed',
    'proposal-status-PROPOSAL_STATUS_REJECTED': 'Rejected',
    'proposal-status-PROPOSAL_STATUS_FAILED': 'Failed',
    'voting-option-VOTE_OPTION_UNSPECIFIED': 'Unspecified',
    'voting-option-VOTE_OPTION_YES': 'Yes',
    'voting-option-VOTE_OPTION_ABSTAIN': 'Abstain',
    'voting-option-VOTE_OPTION_NO': 'No',
    'voting-option-VOTE_OPTION_NO_WITH_VETO': 'No with veto',
    status: 'Status',
    date: 'Date',
    id: 'ID',
    created: 'Created',
    'pre-stake': 'Stake',
    'pre-pre-stake': 'Pre-Stake',
    testnet_network_note:
      'You need to be connected to Kovan Network in your metamask in order to perform any actions on this page.',
    mainnet_network_note:
      'You need to be connected to Mainnet Network in your metamask in order to perform any actions on this page.',
    deposit: 'Deposit',
    delegating_note_high_commission:
      'This validator charges 100% commission or is a injective internal validator, this means that you will NOT earn any staking reward delegating to this validator. Please do not stake with this validator.',
    successfully_claimed: 'Successfully claimed',
    successfully_redelegated: 'Successfully re-delegated',
    successfully_unbonded: 'Successfully unbonded',
    re_delegate: 'Re-Delegate',

    ethereum_mainnet: 'Ethereum Mainnet',
    kovan_testnet: 'Kovan Testnet',
    injective_chain: 'Injective Chain',
    ethereum_mainnet_tooltip: 'Your Ethereum Mainnet INJ balance. ',
    kovan_testnet_tooltip:
      'Your Kovan Testnet INJ balance. This balance is minted from your staked balance so you can use your funds in a safe environment.',
    injective_chain_tooltip:
      'Your Injective Chain INJ balance. With this balance you can start delegating to validators, claim your rewards, re-delegate, make proposals and much more.',
    create_denom: 'Create new Denom',
    create_denom_note:
      "In order to create a new denom, you have to go through the process of transferring assets through our bridge on to our Injective Chain. First, please enter the ERC-20 contract address from the asset you want to transfer, and after the confirmation that the address is an actual ERC-20 token address, you can transfer any amount to our Injective Chain to create a denom for that particular ERC-20 address (if it doesn't already exist).",
    transfer_assets_note:
      "Please make sure that you enter the correct contract ERC-20 token address for the denom you want to create while also having balance in your Kovan's testnet wallet for that token.",
    transfer_assets: 'Transfer to Injective Chain',
    get_balance: 'Get ERC-20 Token Balance',
    contract_address: 'Contract Address',
    enter_valid_address: 'Please enter a valid contract address',
    select_ledger_address: 'Select Ledger Address',
    connect: 'Connect',
    get_more_addresses: 'Get more addresses',
    get_addresses: 'Get Addresses',
    unbond: 'Unbond',
    unbond_your_delegation: 'Unbond your delegation',
    fees_required:
      'You need to have some small amount of INJ to pay for the transaction fees (approx: 0.0015 INJ).',
    voter: 'Voter',
    answer: 'Answer',
    depositor: 'Depositor',
    successfully_deposited: 'Successfully Deposited',
    successfully_voted: 'Successfully Voted',
    makerFeeRate: 'Maker Fee Rate',
    takerFeeRate: 'Taker Fee Rate',
    relayerFeeShareRate: 'Relayer Fee Share Rate',
    minimum_proposal_deposit:
      'You need minimum 1 INJ in your Injective Chain balance to make a proposal',
    completion_time: 'Completion Time',
    completion_time_tooltip:
      'Time time when this re-delegation will be actualized',
    insurance_fund: 'Insurance Fund',
    market: 'Market',
    balance: 'Balance',
    underwrite: 'Underwrite',
    expiresAt: 'Expires At',
    successfully_underwritten: 'Successfully Underwritten',
    successfully_redeemed: 'Successfully Redeemed',
    underwrite_to_insurance_fund: 'Underwrite to {fund} derivative market',
    request_redemption_from_insurance_fund:
      'Request redemption from {fund} insurance pool',
    transfer_usdt: 'Transfer USDT to Injective Chain',
    total_pool_tokens: 'Total Pool Tokens',
    my_pool_tokens: 'My Pool Tokens',
    my_ownership_percentage: 'My Ownership Percentage',
    redemption_notice_period: 'Redemption Notice Period',
    funds: 'Funds',
    request_redemption: 'Request Redemption',
    redemption_amount: 'Redemption Amount',
    estimated_disbursement: 'Estimated Disbursement',
    oracleBase: 'Oracle Base',
    oracleQuote: 'Oracle Quote',
    oracleType: 'Oracle Type',
    expiry_futures_expiry_note:
      'The default expiry date is set for June 25th 2021',
    funds_tooltip: 'The total value locked into the specific insurance market',
    total_pool_tokens_tooltip:
      'The total number of tokens in the insurance pool',
    my_pool_tokens_tooltip:
      'The number of tokens owned by you in the insurance pool. Pending redemption are not included.',
    my_ownership_percentage_tooltip:
      'The % of the total insurance pool owned by you',
    redemption_notice_period_tooltip:
      'Your wait time before you can redeem your deposit',
    insurance_funds_actions_tooltip:
      ' "Underwrite" enables you to deposit in more funds into the pool. "Redeem" allows you to withdraw funds from the pool',
    redemption_amount_tooltip:
      'The number of tokens redeemed from the insurance pool',
    estimated_disbursement_tooltip:
      'The estimated amount you are going to get after the redemption is disbursed',
    insurance_fund_completion_time_tooltip:
      'The time you have to wait before your deposit gets redeemed',
    insurance_funds: 'Insurance Funds'
  },

  maintenance_header:
    'We are upgrading our Injective Chain with new features and optimizations',
  maintenance_subheader: 'Please be patient, we will be back soon.',
  maintenance_body: ''
}
